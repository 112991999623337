import React from 'react'
import { graphql } from 'gatsby'

import { PageLayout, ProductPage } from '../components'

const ProductPageTemplate = ({ data, pageContext }) => {
  const { lang, product, brand, productfamily, skus, attributes, attribute_groups } = pageContext

  return (
    <PageLayout lang={lang} switcher={product}>
      <ProductPage
        product={product}
        productfamily={productfamily}
        productfamilyitems={skus}
        attributes={attributes}
        attribute_groups={attribute_groups}
        brand={brand}
        lang={lang} />
    </PageLayout>
  )
}

export default ProductPageTemplate
